@import "reset.css";
@import "fonts/inter.css";

:root {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  
  --link-color: #FF9211;
  
  // Status bar:
  --theme-color-light: #EAEAEA;
  --theme-color: #141518;
  
  // UI:
  --label-color: #FFF;
  --springboard-background: linear-gradient(#141518, #1F2023);
  --separator-color: rgba(255, 255, 255, 0.05);
  
  --padding: 2em;
  --inter-spacing: 1em;
  
  --label-font-size: 15px;
  --body-font-size: 20px;
  --title-font-size: 28px;
  
  --bold-font-weight: 600;
  --medium-font-weight: 500;
  
  // Selection:
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  
  @supports (font-variation-settings: normal) {
    font-family: "InterVariable", sans-serif;
    font-optical-sizing: auto;
  }
}

html, body {
  width: 100%;
  height: 100%;
  
  overflow: hidden;
  
  background: var(--springboard-background);
}

body {
  color: var(--label-color);
  background-color: var(--theme-color); /* For status bar */
}

.root-view {
  width: 100%;
  height: 100%;
}

// Springboard:

.springboard-view {
  display: flex;
  flex-direction: column;
  
  width: 100%;
  height: 100%;
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--inter-spacing);
    
    padding: var(--padding);
    position: relative;
    
    &:after {
      content: "";
      
      height: 2px;
      
      position: absolute;
      bottom: 0;
      left: var(--padding);
      right: var(--padding);
      
      background: var(--separator-color);
      border-radius: 999px;
    }
    
    .mark {
      $width: 96px;
      $height: 42px;
      
      width: $width;
      height: $height;
      
      background: url("/assets/img/logo.png");
      background: image-set(
        url("/assets/img/logo.png?as=avif") type("image/avif"),
        url("/assets/img/logo.png?as=webp") type("image/webp"),
        url("/assets/img/logo.png") type("image/png")
      );
      
      background-size: $width $height;
      background-repeat: no-repeat;
    }
    
    a {
      --outset-x: 1em;
      --outset-y: 0.5em;
      
      color: var(--link-color);
      font-weight: var(--bold-font-weight);
      text-decoration: none;
      white-space: nowrap;
      
      padding: var(--outset-y) var(--outset-x);
      margin: calc(var(--outset-y) * -1) calc(var(--outset-x) * -1);
      
      transition: all 0.15s ease-out;
      
      &:after {
        content: "";
        
        $width: 18px;
        $height: 18px;
        
        display: inline-block;
        width: $width;
        height: $height;
        
        background: url(data-url:/assets/img/open-external.svg);
        background-size: $width $height;
        background-repeat: no-repeat;
        
        vertical-align: top;
        margin-left: 0.25em;
      }
      
      &:hover {
        box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.5),
                    inset 0px 0px 2px rgba(0, 0, 0, 0.5),
                    0px 2px 2px rgba(255, 255, 255, 0.05);
                    
        background-color: rgb(0, 0, 0, 0.5);
        border-radius: 99px;
        
        transition-duration: 0s;
      }
    }
  }
  
  .page-control {
    padding: var(--padding);
    
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
  
  .springboard-container {
    --rows: 5;
    --columns: 5;
    --gap: 32px;
    
    display: flex;
    flex-direction: row;
    gap: var(--gap);
    
    flex-wrap: nowrap;
    
    overflow-x: scroll;
    overflow: auto;
    
    scroll-snap-type: both mandatory;
    overscroll-behavior-x: contain;
    
    width: 100%;
    height: 100%;
    
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  .page-container {
    flex-shrink: 0;
    
    scroll-snap-align: center;
    scroll-snap-stop: always;
    
    padding: var(--padding);
    padding-bottom: calc(var(--padding) * 2 + 8px); // TODO: Don't hardcode page control height
    
    box-sizing: border-box;
    
    width: 100%;
    height: 100%;
    
    display: grid;
    grid-template-rows: repeat(var(--rows), 1fr);
    grid-template-columns: repeat(var(--columns), 1fr);
    gap: var(--gap);
    
    align-items: center;
    align-content: center;   
  }
  
  .cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--inter-spacing);
    
    cursor: pointer;
    
    &.selected,
    &:active {
      .icon {
        opacity: 0.5;
      }
    }
  }
  
  .label {
    text-align: center;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
    
    font-size: var(--label-font-size);
    font-weight: var(--medium-font-weight);
  }
  
  .icon {
    --size: 108px;
    
    width: var(--size);
    height: var(--size);
    
    img {
      pointer-events: none;
      
      width: inherit;
      height: inherit;
    }
    
    transition: opacity 0.35s ease-out;
    
    &:active {
      transition-duration: 0s;
    }
    
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }
  
}

// Page Control:

.page-control {
  --page-size: 8px;
  --inter-spacing: 8px;
  
  display: flex;
  gap: var(--inter-spacing);
  justify-content: center;
  
  .page-indicator {
    flex-shrink: 0;
    
    width: var(--page-size);
    height: var(--page-size);
    border-radius: var(--page-size);
    
    background: white;
    opacity: 0.35;
    
    transition: opacity 0.35s ease-out;
    
    &.selected {
      opacity: 1;
    }
  }
  
}

// Presentation container:

.presentation-container {
  position: fixed;
  inset: 0;
  
  .presentation-wrapper {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-wrap: nowrap;
    
    overflow-x: scroll;
    overflow: auto;
    
    scroll-snap-type: both mandatory;
    overscroll-behavior-x: contain;
    
    width: 100%;
    height: 100%;
    
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
      display: none;
    }
    
    .spacer {
      width: 100%;
      height: 100%;
    }
    
    & > * {
      flex-shrink: 0;
      
      scroll-snap-align: center;
      scroll-snap-stop: always;
    }
  }
  
}

// Library:

.library-collection-view {
  --item-width: 300px;
  --item-height: 300px;
  --item-gap: 1em;
  
  --first-item-width: var(--item-width);
  --last-item-width: var(--item-width);
  
  display: flex;
  flex-direction: column;
  
  width: 100%;
  height: 100%;
  
  background: white;
  
  header {
    --drop-shadow-height: 8px;
    --drop-shadow-from: rgba(0, 0, 0, 0.1);
    --drop-shadow-to: rgba(0, 0, 0, 0);
    
    display: flex;
    align-items: center;
    
    position: relative;
    
    font-size: var(--label-font-size);
    font-weight: var(--bold-font-weight);
    
    color: rgba(0, 0, 0, 0.9);
    text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.5);
    
    background: linear-gradient(#E1DFDF, #afafaf);
    
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.15),
                inset 0px 2px 0px rgba(255, 255, 255, 0.25),
                inset 0px -1px 0px rgba(0, 0, 0, 0.1);
                
    &:after {
      content: "";
      
      display: block;
      position: absolute;
      pointer-events: none;
      
      right: 0;
      left: 0;
      bottom: calc(var(--drop-shadow-height) * -1.0);
      height: var(--drop-shadow-height);
      
      background: linear-gradient(var(--drop-shadow-from), var(--drop-shadow-to));
    }
    
    .title {
      padding: var(--inter-spacing);
    }
    
    .close-button {
      --light-style: url(data-url:/assets/img/close-button.png);
      --dark-style: url(data-url:/assets/img/close-button-dark.png);
      
      --style: var(--light-style);
      
      $size: 27px;
      
      width: $size;
      height: $size;
      
      margin-left: auto;
      margin-right: 0.85em;
      
      appearance: none;
      -moz-appearance: none;
      border: none;
      cursor: pointer;
      
      background: var(--style);
      background-size: 100% 100%;
      
      &:active {
        opacity: 0.75;
      }
    }
  }
  
  .collection-wrapper {
    display: flex;
    
    width: 100%;
    height: 100%;
  }
  
  .collection-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
    align-items: center;
    
    box-sizing: border-box;
    padding: 1em;
    gap: var(--item-gap);
    
    width: 100%;
    height: 100%;
    
    min-height: calc(var(--item-height) + 1em * 2);
    
    padding-left: calc(50% - var(--first-item-width) / 2);
    padding-right: calc(50% - var(--last-item-width) / 2);
    
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: both mandatory;
    
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
      display: none;
    }
    
    .cell {
      flex-shrink: 0;
      
      scroll-snap-align: center;
      
      width: var(--item-width);
      height: var(--item-height);
      
      cursor: pointer;
      
      .image {
        display: block;
        
        width: 100%;
        height: 100%;
        
        img {
          width: inherit;
          height: inherit;
        }
      }
      
      // Loading state:
      
      .image {
        background-color: #eaeaea;
        
        img {
          transition: opacity 0.25s ease-out;
        }
        
      }
      
      &.loading {
        img {
          opacity: 0;
        }
      }
    }
  }
  
  .detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding: var(--padding);
    padding-top: 0;
    
    color: rgba(0, 0, 0, 0.77);
    font-size: var(--body-font-size);
    line-height: 1.2;
    
    .title {
      font-size: var(--title-font-size);
      font-weight: var(--bold-font-weight);
      
      color: #000;
      
      position: relative;
      width: 100%;
      
      .marquee-container {
        --over: 32px;
        --inter-spacing: 32px;
        
        --gradient-stop-opaque: rgba(255, 0, 0, 1);
        --gradient-stop-translucent: rgba(255, 0, 0, 0);
        
        position: relative;
        
        .marquee-text-wrapper {
          display: inline-flex;
          gap: var(--inter-spacing);
        }
        
        white-space: nowrap;
        
        padding-left: var(--over);
        padding-right: var(--over);
        margin-left: calc(-1 * var(--over));
        
        overflow: hidden;
        box-sizing: border-box;
        width: calc(100% + var(--over) * 2);
        
        mask: linear-gradient(to right, var(--gradient-stop-translucent) 0%, var(--gradient-stop-opaque) var(--over), var(--gradient-stop-opaque) calc(100% - var(--over)), var(--gradient-stop-translucent) 100%);
      }
    }
    
    .author {
      font-weight: var(--medium-font-weight);
    }
    
    .year {
      font-weight: 500;
      font-size: var(--label-font-size);
      font-variant-numeric: tabular-nums;
    }
    
    .open-button {
      display: inline-block;
      width: min-content;
      
      margin-top: var(--inter-spacing);
      padding: calc(var(--inter-spacing) * 0.5) var(--inter-spacing);
      
      color: white;
      font-weight: var(--medium-font-weight);
      text-decoration: none;
      background: black;
      
      border-radius: 999px;
    }
    
    & > * {
      margin-top: 0.25em;
    }
    
  }
  
  // Music
  
  &.music {
    --item-width: 200px;
    --item-height: 200px;
    
    .cell {
      position: relative;
      
      .image:after {
        content: "";
        
        display: block;
        position: absolute;
        inset: 0;
        
        box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.15);
      }
      
      &:after {
        content: "";
        display: block;
        
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        height: 1px;
        
        background: rgba(0, 0, 0, 0.25);
        filter: blur(2px);
        
        z-index: 999;
        pointer-events: none;
      }
      
    }
    
    .reflection {
      display: block;
      pointer-events: none;
      
      background-size: 100% 100%;
      
      width: 100%;
      height: 100%;
      
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      
      transform: translateY(100%) scaleY(-1);
    }
    
    .collection-wrapper {
      position: relative;
      
      &:after {
        content: "";
        
        display: block;
        position: absolute;
        pointer-events: none;
        
        left: 0;
        right: 0;
        top: calc(50% + var(--item-height) / 2);
        height: calc(50% - var(--item-height) / 2);
        max-height: var(--item-height);
        
        background: linear-gradient(rgba(255, 255, 255, 0.5) 0px, rgba(255, 255, 255, 1) 100%);
        
        -webkit-backdrop-filter: blur(4px) saturate(1.5);
      }
    }
    
  }
  
  // Movies
  
  &.movies {
    --item-width: 200px;
    --item-height: 300px;
    --item-gap: 4em;
    
    background: linear-gradient(#000, #262B2B);
    
    header {
      color: #fff;
      text-shadow: 0px -1px 1px rgba(0, 0, 0, 0.5);
      
      background: linear-gradient(#343434, #292929);
      
      box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.15),
                  inset 0px -1px 0px rgba(255, 255, 255, 0.05);
                  
      .close-button {
        --style: var(--dark-style);
      }
    }
    
    .cell {
      position: relative;
      
      picture.image:after {
        content: "";
        
        --dx: -62px;
        --dy: -38px;
        --width: 324px;
        --height: 376px;
        
        display: block;
        position: absolute;
        
        left: var(--dx);
        top: var(--dy);
        width: var(--width);
        height: var(--height);
        
        background-image: url("/assets/img/movie-case.png");
        background-image: image-set(
          url("/assets/img/movie-case.png?as=avif") type("image/avif"),
          url("/assets/img/movie-case.png?as=webp") type("image/webp"),
          url("/assets/img/movie-case.png") type("image/png")
        );
        
        background-size: var(--width) var(--height);
      }
      
      .image {
        background-color: #222;
      }
    }
    
    .detail {
      color: rgba(255, 255, 255, 0.65);
      text-shadow: 0px -1px 1px rgba(0, 0, 0, 0.5);
      
      .title {
        color: white;
      }
      
      .open-button {
        box-shadow: inset 0px 0px 1px rgba(255, 255, 255, 0.5),
                    inset 0px 1px 1px rgba(255, 255, 255, 0.15),
                    0px 0px 1px rgba(0, 0, 0, 0.15),
                    0px 2px 8px rgba(0, 0, 0, 0.15);
        background: linear-gradient(#696969, rgba(105, 105, 105, 0.6));
      }
    }
  }
  
  // Games
  
  &.games {
    background: linear-gradient(color(display-p3 0.047 0.023 0.11), color(display-p3 0.288 0.131 0.671));
    
    header {
      color: #fff;
      text-shadow: 0px -1px 1px rgba(0, 0, 0, 0.5);
      
      background: linear-gradient(color(display-p3 0.126 0.11 0.153), color(display-p3 0.11 0.094 0.145));
      
      box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.15),
                  inset 0px -1px 0px rgba(255, 255, 255, 0.05);
                  
      .close-button {
        --style: var(--dark-style);
      }
    }
    
    .cell {
      
      .image {
        position: relative;
        
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.15);
        
        img {
          border-radius: inherit;
        }
        
      }
      
      picture.image:after {
        content: "";
        display: block;
        
        position: absolute;
        inset: 0;
        border-radius: inherit;
        
        box-shadow: inset 0px 0px 1px rgba(255, 255, 255, 0.35),
                    0px 2px 4px rgba(0, 0, 0, 0.5),
                    0px 2px 16px rgba(0, 0, 0, 0.15);
      }
      
      
    }
    
    .detail {
      color: rgba(255, 255, 255, 0.65);
      text-shadow: 0px -1px 1px rgba(0, 0, 0, 0.5);
      
      .title {
        color: white;
      }
      
      .open-button {
        color: #2D1266;
        text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.25);
        
        box-shadow: inset 0px 0px 1px rgba(255, 255, 255, 0.5),
                    inset 0px 1px 1px rgba(255, 255, 255, 0.15),
                    0px 0px 1px rgba(0, 0, 0, 0.15),
                    0px 2px 8px rgba(0, 0, 0, 0.15);
                    
        background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5));
      }
    }
  }
  
  // Books:
  
  &.books {
    --item-width: 200px;
    --item-height: 300px;
    
    background: url("/assets/img/shelf-background.png");
    background: image-set(
      url("/assets/img/shelf-background.png?as=avif") type("image/avif"),
      url("/assets/img/shelf-background.png?as=webp") type("image/webp"),
      url("/assets/img/shelf-background.png") type("image/png")
    );
    
    background-size: 2730px 910px;
    background-position: left center;
    background-color: color(display-p3 0.482 0.271 0.086);
    
    .cell {
      position: relative;
      
      .image {
        border-radius: 2px;
        background-color: #E4E4E6;
        
        img {
          border-radius: inherit;
        }
      }
      
      picture.image:after {
        content: "";
        display: block;
        
        pointer-events: none;
        
        --inset: 20px;
        --slice: 60; // inset * 3 // scale
        
        position: absolute;
        inset: calc(var(--inset) * -1);
        border: var(--inset) solid transparent;
        
        border-image-source: url("/assets/img/book.png");
        border-image-source: image-set(
          url("/assets/img/book.png?as=avif") type("image/avif"),
          url("/assets/img/book.png?as=webp") type("image/webp"),
          url("/assets/img/book.png") type("image/png")
        );
        
        border-image-slice: var(--slice) fill;
        
        mix-blend-mode: multiply;
      }
      
      // Loading:
      
      .image {
        transition: transform 0.5s ease-in-out;
        
        transform: perspective(600) rotateX(1deg);
        
        img {
          transition: none; /* override default */
        }
      }
      
      &.loading {
        
        .image {
          transform: perspective(600) translateY(-100vh);
        }
        
        img {
          opacity: 1;
        }
      }
    }
    
    header {
      color: rgb(27, 0, 0);
      text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.25);
      
      background: linear-gradient(rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0));
      
      box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.15),
                  inset 0px -1px 0px rgba(255, 255, 255, 0.05),
                  inset 0px -2px 1px rgba(255, 255, 255, 0.1),
                  0px 1px 6px rgba(0, 0, 0, 0.25);
                  
      --drop-shadow-height: 40px;
      --drop-shadow-from: rgb(118, 54, 3);
      --drop-shadow-to: rgba(0, 0, 0, 0);
      
    }
    
    .collection-container {
      background: url("/assets/img/shelf-foreground.png");
      background: image-set(
        url("/assets/img/shelf-foreground.png?as=avif") type("image/avif"),
        url("/assets/img/shelf-foreground.png?as=webp") type("image/webp"),
        url("/assets/img/shelf-foreground.png") type("image/png")
      );
      
      background-size: 2730px 910px;
      background-position: left center;
      
      padding-bottom: 4em; /* Extra room for shelf bottom */
      min-height: calc(var(--item-height) + 8em);
    }
    
    .detail {
      color: rgba(255, 255, 255, 0.75);
      text-shadow: 0px -1px 1px rgba(66, 0, 0, 0.25);
      
      .title {
        color: white;
      }
      
      .open-button {
        color: rgb(27, 0, 0);
        text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.25);
        
        box-shadow: inset 0px 0px 1px rgba(255, 255, 255, 0.5),
                    inset 0px 1px 1px rgba(255, 255, 255, 0.15),
                    0px 0px 1px rgba(0, 0, 0, 0.15),
                    0px 2px 8px rgba(0, 0, 0, 0.15);
                    
        background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5));
      }
    }
  }
  
}

// Pages:

main {
  display: flex;
  flex-direction: column;  
  align-items: center;
  justify-content: center;
  
  text-align: center;
  line-height: 1.4;
  font-size: var(--body-font-size);
  
  padding: var(--padding);
  gap: var(--inter-spacing);
  
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  
  h1 {
    font-size: var(--title-font-size);
    font-weight: var(--bold-font-weight);
  }
  
  a {
    --label-color: #fff;
    
    margin-top: var(--padding);
    
    border-radius: 999px;
    
    padding: calc(var(--padding) * 0.5) var(--padding);
    
    white-space: nowrap;
    text-decoration: none;
    font-weight: var(--medium-font-weight);
    font-size: var(--label-font-size);
    
    color: var(--label-color);
    border: 2px solid var(--separator-color);
    transition: all 0.25s ease-out;
    
    &:hover {
      transition-duration: 0s;
      background-color: var(--separator-color);
    }
    
    &:active {
      border-color: var(--label-color);
    }
  }
}

// Pre-loading hack:

body:after {
  content: "";
  
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  
  pointer-events: none;
  visibility: hidden;
  
  background: url("/assets/img/movie-case.png"),
              url("/assets/img/book.png"),
              url("/assets/img/shelf-background.png"),
              url("/assets/img/shelf-foreground.png");
              
  background: image-set(
    url("/assets/img/movie-case.png?as=avif") type("image/avif"),
    url("/assets/img/movie-case.png?as=webp") type("image/webp"),
    url("/assets/img/movie-case.png") type("image/png")
  ),
  image-set(
    url("/assets/img/book.png?as=avif") type("image/avif"),
    url("/assets/img/book.png?as=webp") type("image/webp"),
    url("/assets/img/book.png") type("image/png")
  ),
  image-set(
    url("/assets/img/shelf-background.png?as=avif") type("image/avif"),
    url("/assets/img/shelf-background.png?as=webp") type("image/webp"),
    url("/assets/img/shelf-background.png") type("image/png")
  ),
  image-set(
    url("/assets/img/shelf-foreground.png?as=avif") type("image/avif"),
    url("/assets/img/shelf-foreground.png?as=webp") type("image/webp"),
    url("/assets/img/shelf-foreground.png") type("image/png")
  );
}

